<template>
  <views></views>
</template>

<script>
import views from './view.vue';

export default {
  name: 'goods-list-create',
  components: { views },
};
</script>
